import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-container-start" }

import { computed } from 'vue';
import { nth } from 'lodash-es';
import { Search, Edit, Download, Delete, PreviewOpen, Copy, Filter } from '@icon-park/vue-next';
import type { ThemeKeywordsType } from '@/hooks/view/type';
import { useTheme } from '@/hooks/view/ui/theme';

interface FtlBtnProps {
  theme: ThemeKeywordsType;
  icon?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FtlBtn',
  props: {
    theme: {},
    icon: { type: Boolean, default: false }
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

const iconMap: Map<ThemeKeywordsType, any> = new Map([
  ['edit', Edit],
  ['delete', Delete],
  ['view', PreviewOpen],
  ['search', Search],
  ['download', Download],
  ['copy', Copy],
  ['filter', Filter],
]);
const props = __props;
const emits = __emit;
const theme = computed(() => useTheme(props.theme));
const borderColor = computed(() => nth(theme.value, 0));
const backgroundColor = computed(() => nth(theme.value, 1));
const color = computed(() => nth(theme.value, 2));
const iconComp = computed(() => iconMap.get(props.theme));

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, _mergeProps({
    onClick: _cache[0] || (_cache[0] = (e) => emits('click', e))
  }, _ctx.$attrs, {
    style: {
      color: !_ctx.$attrs.disabled ? color.value : '#888',
      borderColor: _ctx.icon && !_ctx.$attrs.disabled ? borderColor.value : '#E4E7ED',
      backgroundColor: !_ctx.$attrs.disabled ? backgroundColor.value : '#e4e7ed'
    }
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(iconComp.value), {
          class: _normalizeClass([!_ctx.icon ? 'icon-margin' : ''])
        }, null, 8, ["class"])), [
          [_vShow, !_ctx.$attrs.loading]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default")
        ], 512), [
          [_vShow, !_ctx.icon]
        ])
      ])
    ]),
    _: 3
  }, 16, ["style"]))
}
}

})