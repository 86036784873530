import type { ThemeKeywordsType, TransferLineThemeKeyWords } from '../type';

/**
 * 0 边框色
 * 1 背景色
 * 2 字体颜色
 */
const yellowTheme = Object.freeze(['#DB952C', '#F8E9D2', '#DB952C']);
const deleteTheme = Object.freeze(['#F56C6C', '#FCE8E8', '#F56C6C']);
const greenTheme = Object.freeze(['#429096', '#E2F0F1', '#429096']);
const viewTheme = Object.freeze([
  'var(--oms-admin-primary-color)',
  'var(--oms-admin-primary-plain-color)',
  'var(--oms-admin-primary-color)',
]);
const disableTheme = Object.freeze(['#E4E7ED', '#DFDFDF', '#888']);
const spainTheme = Object.freeze(['#e1f1fc', '#e1f1fc', '#2c6bc3']);
const deltaH7Theme = Object.freeze([
  'var(--oms-admin-success-plain-color)',
  'var(--oms-admin-success-plain-color)',
  'var(--oms-admin-success-color)',
]);
const blueTheme = Object.freeze(['#e1f1fc', '#e1f1fc', '#2c6bc3']);
const xcrTheme = Object.freeze([
  'var(--oms-admin-warning-plain-color)',
  'var(--oms-admin-warning-plain-color)',
  'var(--oms-admin-warning-color)',
]);
const cdgTheme = Object.freeze([
  'var(--oms-admin-primary-plain-color)',
  'var(--oms-admin-primary-plain-color)',
  'var(--oms-admin-primary-color)',
]);
const deltaXTheme = Object.freeze([
  'var(--oms-admin-warning-plain-color)',
  'var(--oms-admin-warning-plain-color)',
  'var(--oms-admin-warning-color)',
]);
const deltaXMixTheme = Object.freeze([
  'var(--oms-admin-error-plain-color)',
  'var(--oms-admin-error-plain-color)',
  'var(--oms-admin-error-color)',
]);
const purpleTheme = Object.freeze(['#f7e1fb', '#f7e1fb', '#a13ab4']);
const purpleTheme2 = Object.freeze(['#f5eefb', '#f5eefb', '#762eba']);

const transferLineTheme: Array<[TransferLineThemeKeyWords, readonly string[]]> = [
  ['COLICOLI', greenTheme],
  ['FTL_PLUS', yellowTheme],
  ['CP_FR', blueTheme],
  ['COLISSIMO', purpleTheme],
  ['COLISSIMO_SIG', purpleTheme2],
];

const themeMap: Map<ThemeKeywordsType, readonly string[]> = new Map([
  ['edit', yellowTheme],
  ['delete', deleteTheme],
  ['download', greenTheme],
  ['search', greenTheme],
  ['view', viewTheme],
  ['disabled', disableTheme],
  ['SPAIN', spainTheme],
  ['DELTA_H7', deltaH7Theme],
  ['MAD', blueTheme],
  ['DELTA_X', deltaXTheme],
  ['DELTA_X_MIX', deltaXMixTheme],
  ['DELTA_G', purpleTheme],
  ['CDG', cdgTheme],
  ['XCR', xcrTheme],
  ['filter', greenTheme],
  ...transferLineTheme,
]);

const useTheme = (type: ThemeKeywordsType): readonly string[] => themeMap.get(type) as readonly string[];

export { useTheme };
