import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "flex-container-start" }
const _hoisted_3 = ["multiple", "id"]

import { Ref, ref } from 'vue';
import { read, utils } from 'xlsx';
import { get, isEqual } from 'lodash-es';
import { success, stringhash, randomString } from '@/utils';


interface UploadBtnProps {
  buttonText?: string; // 上传按钮描述
  needUpload: boolean; // 是否需要上传
  modelValue?: any; // 绑定状态值
  uploadRemote?: (...args: any[]) => Promise<any>;
  errorValue?: any; // 报错绑定状态值
  multiple?: boolean; // 是否上传多个文件
  parse?: boolean; // 是否解析文件
  refresh?: boolean; // 上传后是否刷新
  query?: Record<string, string>; // 上传需要参数
  isButton?: boolean; // 是否是按钮样式
  file?: any; // 文件对象
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FileUploadBtn',
  props: {
    buttonText: { default: 'action.click.upload' },
    needUpload: { type: Boolean, default: false },
    modelValue: {},
    uploadRemote: {},
    errorValue: {},
    multiple: { type: Boolean, default: false },
    parse: { type: Boolean, default: false },
    refresh: { type: Boolean, default: false },
    query: {},
    isButton: { type: Boolean, default: true },
    file: {}
  },
  emits: ['update:modelValue', 'refresh', 'handler', 'update:errorValue', 'update:file'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emits = __emit;
const loading: Ref<boolean> = ref(false);
const uniqueId = stringhash(randomString(7));

const uploadFile = async (file: File) => {
  if (!props.multiple && props.needUpload && props.uploadRemote) {
    try {
      loading.value = true;
      const data = await props.uploadRemote(file, props.query);
      if (data) emits('update:modelValue', data);
      success('action.upload.success');
      if (props.refresh) emits('refresh');
    } catch (e) {
      emits('update:errorValue', get(e, 'response.data'));
    } finally {
      loading.value = false;
    }
  }
};
const uploadParsedData = (originFile: File) => {
  const fileReader = new FileReader();
  fileReader.onload = async ({ target: { result } }: any) => {
    const workbook = read(result, { type: 'binary', cellDates: true, cellNF: true });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = utils.sheet_to_json(worksheet);
    const state = { result: '' };
    emits('handler', data, state);
    emits('update:modelValue', state.result);
  };
  fileReader.readAsBinaryString(originFile);
};
const getFile = ({ target: { files } }: any) => {
  emits('update:file', files[0]);
  if (props.parse) {
    uploadParsedData(files[0]);
  } else {
    uploadFile(files[0]);
  }
};
const getLoadingStatus = () => loading.value;

__expose({
  getLoadingStatus
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _mergeProps({
      class: {
      'el-button': props.isButton,
      'el-button--primary': true,
      'is-loading': loading.value,
      'el-button--small': true
    },
      for: `${_unref(uniqueId)}` || 'fileUpload'
    }, props.isButton ? {} : { style: { color: '#409EFF' } }), [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon"),
        _createTextVNode(" " + _toDisplayString(loading.value ? _ctx.$t('Uploading...') : _ctx.$t(_ctx.buttonText)), 1)
      ])
    ], 16, _hoisted_1),
    _createElementVNode("input", {
      type: "file",
      style: {"display":"none"},
      multiple: _ctx.multiple,
      name: "fileUpload",
      id: `${_unref(uniqueId)}` || 'fileUpload',
      onClick: _cache[0] || (_cache[0] = (event) => (event.target.value = null)),
      onChange: getFile
    }, null, 40, _hoisted_3)
  ], 64))
}
}

})